import { Injectable } from '@angular/core';
import { IApi } from '../http-handler/common/contracts/api';
import { HttpClient } from '@angular/common/http';
import { GenericApi } from '../http-handler/common/generic-api';
import { Contact, ContactSkill } from '../models/contacts.model';
import { AudienceService } from './audience.service';

@Injectable({
  providedIn: 'root',
})
export class ContactService {
  contacts: IApi<Contact>;
  skills: IApi<ContactSkill>;
  constructor(
    private http: HttpClient,
    public audienceService: AudienceService
  ) {
    this.contacts = new GenericApi<Contact>('contacts', http);
    this.skills = new GenericApi<Contact>('contactSkills', http);
  }

  exportContacts(audienceIds: number[]): Promise<string> {
    return this.contacts
      .create({ audienceIds }, 'create/excel')
      .then((d: any) => {
        return d.downloadUrl as string;
      })
      .catch((err) => {
        return err;
      });
  }

}
