import { Account } from './accounts.model';
import { Contact } from './contacts.model';
import { AdminEquipment } from './equipment.model';
import { LeadServices } from './lead-service.model';
import { SpacesModel } from './spaces.model';

export class Lead {
  id?: number;
  name?: string;
  title?: string;
  createdAt?: string;
  position?: number;
  description?: string;
  medium?: string;
  leadNumber?: string;
  shootAddress?: string;
  approxAmount?: number;
  priority?: number;
  status?: string;
  leadSource?: string;
  adminId?: number;
  accountId?: number;
  account?: Account = new Account();
  contactId?: number;
  contact?: Contact = new Contact();
  admin?: Admin = new Admin();
  leadServices?: LeadServices[] = [];
  btnDisable?: Boolean;
  sources?: boolean;
  leadAddresses?: lAddress[] = [];
  referralContactId?: number;
  referralAccountId?: number;
  leadReferral?: LeadReferral = {};
  moreAddress?: boolean;
  reasonForClosedLost?: string;
  businessName?: string;
  pipelineStageId?: number;
  pipelineStage?: PipeLineStage;
  studio?: SpacesModel;

}


export enum LEAD_FOLLOW_UP {
  TOMORROW = 'tomorrow',
  NEXT_BUSINESS_DAY = 'nextBusinessDay',
  TWO_BUSINESS_DAY = '2BusinessDay',
  THREE_BUSINESS_DAY = '3BusinessDay',
  FIVE_BUSINESS_DAY = '5BusinessDay',
  TEN_BUSINESS_DAY = '10BusinessDay',
}
export class PipeLineStage {
  id: number;
}
export class LeadReferral {
  id?: number;
  accountId?: number;
  leadId?: number;
  contactId?: number;
  referralAccount?: Account = new Account();
  referralContact?: Contact = new Contact();
}

export class lAddress {
  id?: number;
  leadId?: number;
  dealId?: number;
  address?: string;
  disable?: boolean = false;
  suiteNumber?: number;
  landmark?: string;
  addressCoordinates?: number[] = [];
  addressCoordinatesPolygon?: any[] = [];
}

export class Admin {
  id?: number;
  salutation?: string;
  firstName?: string;
  lastName?: string;
  displayName?: string;
  email?: string;
  roleType?: string;
  token?: string;
  password?: string;
  mobile?: number;
  profilePercent?: number;
  department?: string;
  status?: string;
  adminType?: string;
  fullName?: string;
  imgUrl?: string;
  description?: string;
  profileStatus?: string;
  salesAmount?: number;
  services?: string;
  adminEquipments?: AdminEquipment[] = [];
  username?: string;
  constructor() {
    if (this.firstName) {
      this.fullName = this.firstName;
    }
    if (this.lastName) {
      this.fullName += this.lastName;
    }
  }
}
