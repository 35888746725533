<div class="d-flex align-center justify-content-between">
  <!-- <div font-25 *ngIf="!data">
    Add Email List
  </div>
  <div font-25 *ngIf="data">
    Edit Email List
  </div> -->
  <font-25 weight="R" other="other" shadow="textShadow"> {{ data ? 'Edit Email List' :'Add Email List'}}</font-25>
  <div>
    <img width="22px" class="cursor" (click)="dialogRef.close(true)" src="assets/svg/close (2).svg" alt="">
  </div>
</div>

<form [formGroup]="form">
  <div class="col-12 row mt-3 mr-0 ml-0 mb-0 p-0">
    <div class="col-6 pl-0">
      <label>Audience Name<i>*</i></label>
      <input InputUi formControlName="name" autocomplete="off" maxlength="20" placeholder="Audience Name" type="text">
    </div>
    <div class="col-6 pr-0">
      <label>Market</label>
      <div class="">
        <mat-select class="p-relative"  #market (openedChange)="toggleMarketDropdown()" disableOptionCentering placeholder="Market" SelectUi formControlName="market">
          <mat-option [value]="'mohali'">Mohali</mat-option>
        </mat-select>
        <div class="append flex">
          <img (click)="market.open()" [ngClass]="{'rotated': isMarketArrowRotated}" src="assets/images/icon_drop down.png">
        </div>
      </div>
    </div>
    <div class="col-6 pl-0 mt-3">
      <label>Audience Preference</label>
      <input InputUi type="text" formControlName="address" placeholder="Location">
    </div>

    <div class="col-6 pr-0 mt-3">
      <label>State</label>
      <div>
        <mat-select #state (openedChange)="toggleStateDropdown()" disableOptionCentering placeholder="State" SelectUi formControlName="state">
          <mat-option [value]="'punjab'">Punjab</mat-option>
        </mat-select>
        <div class="append flex">
          <img (click)="state.open()" [ngClass]="{'rotated': isStateArrowRotated}" src="assets/images/icon_drop down.png">
        </div>
      </div>
    </div>

    <div class="col-12 px-0 mt-3">
      <label>Select Service</label>
      <div>
        <mat-select #services (openedChange)="toggleServiceDropdown()" disableOptionCentering placeholder="Select Service" SelectUi formControlName="serviceId">
          <mat-option (click)="selectService(item)" *ngFor="let item of service?.items" [value]="item?.id"> {{ item?.name | titlecase }} </mat-option>
        </mat-select>
        <div class="append flex">
          <img (click)="services.open()" [ngClass]="{'rotated': isServiceArrowRotated}" src="assets/images/icon_drop down.png">
        </div>
      </div>
    </div>

    <div class="col-12 px-0 mt-3">
      <label>Service Assigned</label>
      <div class="service-border">
        <div class="cross-chip purple mb-2" mat-ripple (click)="item.selected = true"
          [class.selected]="item?.selected" *ngFor="let item of leadService?.items; let i = index">
          {{item?.name | titlecase}}
          <span *ngIf="item.selected" (click)="item.selected = false;$event.stopPropagation();" class="cross">x</span>
        </div>
        <div *ngIf="serviceName && !leadService?.items?.length"><label>Assigned services not available!</label></div>
      </div>
    </div>

    <div class="col-12 px-0 mt-3">
      <label>Note</label>
      <textarea formControlName="note" InputUi maxlength="200" type="text" autocomplete="off"></textarea>
    </div>
  </div>

  <footer class="text-right mt-5">
    <button *ngIf="!data" class="theme-btn blue" (click)="createAudience()">Done</button>
    <button *ngIf="data" class="theme-btn blue" (click)="updateAudience()">Update</button>
  </footer>
</form>